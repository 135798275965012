<template>
  <v-select
    v-model="passengerId"
    :items="items"
    :loading="loading"
    :disabled="disabled || loading"
    :item-text="(val) => { return getPassengerUser(val)}"
    :outline="outline"
    item-value="id"
    :class="`full-width combobox-passenger` + classes"
    hide-details
    :clearable="clearable"
    :label="label"
    :multiple="multiple"
    @change="changePassenger">
    <template v-slot:prepend-item>
        <div
        class="row px-3 py-1">
          <v-text-field
            v-model="searchPassengerName"
            filled
            placeholder="Search"
            hide-details
            @input="getPassengerListFetch"/>
        </div>
    </template>
  </v-select>
</template>

<script>
import request from '../../helpers/request'
import _debounce from 'lodash/debounce'

export default {
  mixins: [request],
  props: {
    brandList: {
      type: Array,
      default: () => { return [] }
    },
    classes: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number | String | null,
      default: null
    },
    label: {
      type: String,
      default: 'Customer'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    excludeIds: {
      type: Array,
      default: () => { return [] }
    },
    queryParams: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      searchPassengerName: '',
      items: [],
      loading: false,
      oldBrandList: this.brandList
    }
  },
  async beforeMount () {
    this.getPassengerList(this.brandListIds.join(','))

    // if (this.passengerPropId) {
    //   this.changePassenger(this.passengerPropId)
    // }
  },
  computed: {
    brandListIds () {
      let ids = []
      try {
        ids = this.brandList.map(item => item.id || item)
      } catch (error) {}
      return ids
    },
    passengerId: {
      get () {
        return this.value ? parseInt(this.value) : this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    brandList: {
      handler (val) {
        if (this.oldBrandList.some(item => !val.includes(item))) {
          this.oldBrandList = val
          this.passengerId = null
          this.getPassengerList(this.brandListIds.join(','))
        }
      },
      deep: true
    },
    excludeIds: {
      handler (val) {
        if (this.excludeIds) {
          this.items = this.items.filter(item => !this.excludeIds.includes(item.id))
        }
      },
      deep: true
    },
    passengerId: {
      handler (val) {
        this.getPassengerList()
      },
      deep: true
    }
  },
  methods: {
    reset () {
      this.passengerId = null
    },
    getPassengerUser (val) {
      return val.user && `${val.name || ''} ${val.user.email ? '(' + val.user.email + ')' : ''} id: ${val.id}`
    },
    getPassengerListFetch: _debounce(function (val) {
      if (val !== this.searchPassengerNameOld && val != null) {
        this.searchPassengerNameOld = val
        this.getPassengerList(this.brandListIds.join(','), val)
      }
    }, 500),
    getPassengerList (brandIds = null, search = null) {
      new Promise((resolve, reject) => {
        let url = '/passengers?pageSize=100'

        if (brandIds) {
          url += `&brands=${brandIds}`
        }

        if (search && search.length) {
          url += `&search=${search}`
        }

        this.loading = true

        this.request('GET', url, this.queryParams, async ({ data }) => {
          this.items = data.data
          if (this.passengerId && !search) {
            const currentPassenger = await this.getPassenger(this.passengerId)
            this.items = this.items.filter(passenger => passenger.id !== this.passengerId)
            this.items.unshift(currentPassenger)
          }

          if (this.excludeIds) {
            this.items = this.items.filter(item => !this.excludeIds.includes(item.id))
          }

          this.loading = false
          resolve()
        }, null, (error) => {
          this.loading = false
          reject(error)
        })
      })
    },
    changePassenger (val) {
      let passenger

      if(this.multiple) {
        passenger = this.items.filter(item => item && val.includes(item.id))
      } else {
        passenger = this.items.find(item => item && item.id === val)
      }

      this.$emit('changePassenger', passenger || null)
    },
    async getPassenger (id) {
      const { data } = await this.request('GET', `/passengers/${id}`, {})
      return data
    }
  }
}
</script>
<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Review information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      :items="subjectList"
                      v-model="review.subject"
                      :rules="[v => Boolean(v) || 'Please select a subject']"
                      label="Subject"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="review.rating"
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        :rules="[v => Boolean(v) || 'Please provide a rating']"
                        type="number"
                        label="Rating"
                      />
                    </InputHotKeyWrapper>
                  </v-flex>
                  <v-flex
                    v-if="review.subject !== REVIEW_SUBJECT_APP"
                    xs12
                    sm6>
                    <passengers-input
                      v-model="review.passenger"
                      label="Customer id" />
                  </v-flex>
                  <v-flex
                    v-if="review.subject !== REVIEW_SUBJECT_APP"
                    xs12
                    sm6>
                    <drivers-input
                      v-model="review.driver"
                      label="Driver id" />
                  </v-flex>
                  <v-flex
                    v-if="review.subject !== REVIEW_SUBJECT_APP"
                    xs12
                    sm6>
                    <v-autocomplete
                      v-model="review.ride"
                      :items="rideList"
                      :item-text="(val) => { return 'id: ' + val.id }"
                      label="Ride id"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                  >
                    <v-switch
                      v-model="review.public"
                      label="Public"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                  >
                    <v-switch
                      v-model="review.anonymous"
                      label="Anonymous"
                    />
                  </v-flex>
                  <v-flex
                    xs12>
                    <v-textarea
                      v-model="review.explanation"
                      :rules="[v => Boolean(v) || 'Please provide a explanation']"
                      label="Explanation"
                    />
                  </v-flex>

                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH, REVIEW_SUBJECTS, REVIEW_SUBJECT_APP } from '../../../../constants/common'
import PassengersInput from '../../../../modules/components/PassengersInput.vue'
import DriversInput from '../../../../modules/components/DriversInput.vue'

export default {
  mixins: [Request],
  components: { PassengersInput, DriversInput },
  data () {
    return {
      driverList: [],
      passengerList: [],
      rideList: [],
      TEXT_FIELD_MAX_LENGTH,
      REVIEW_SUBJECT_APP,
      subjectList: REVIEW_SUBJECTS,
      loading: true,
      review: {},
      setNewImage: false
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/reviews/${this.$route.params.id}`, {}, ({ data }) => {
        let _review = {
          subject: data.subject,
          anonymous: data.anonymous,
          public: data.public,
          rating: data.rating,
          explanation: data.explanation
        }

        if (data.passenger) {
          _review = {
            ..._review,
            passenger: data.passenger.id
          }
        }

        if (data.ride) {
          _review = {
            ..._review,
            ride: data.ride.id
          }
        }

        if (data.driver) {
          _review = {
            ..._review,
            driver: data.driver.id
          }
        }

        this.review = _review

        let getRideList = () => new Promise((resolve) => {
          this.request('GET', '/rides?pageSize=9999', {}, ({ data }) => {
            this.rideList = data.data
            resolve()
          })
        })

        Promise.all([getRideList()]).then(() => {

        })
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      let body = {
        data: {
          'rating': this.review.rating,
          'subject': this.review.subject,
          'explanation': this.review.explanation,
          'public': Boolean(this.review.public),
          'anonymous': Boolean(this.review.anonymous)
        }
      }

      if (this.review.subject !== REVIEW_SUBJECT_APP) {
        body = {
          ...body,
          data: {
            ...body.data,
            'passenger': this.review.passenger,
            'driver': this.review.driver,
            'ride': this.review.ride
          }
        }
      }

      this.request('PATCH', `/reviews/${this.$route.params.id}`, body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Review is updated'
        })

        this.$router.push('/tables/reviews')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview {
    max-width: 100%;
  }
</style>
